/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import * as React from "react"
import loadable from "@loadable/component"
import "semantic-ui-css/semantic.min.css"
import "./src/assets/scss/app.scss"
const WindowProvider = loadable(() =>
  import("./src/window/window.provider.tsx")
)
const FirebaseProvider = loadable(() =>
  import("./src/services/firebase/firebase.provider")
)

export const wrapRootElement = ({ element }) => (
  <WindowProvider>
    <FirebaseProvider>{element}</FirebaseProvider>
  </WindowProvider>
)
