exports.components = {
  "component---src-pages-app-tsx": () => import("./../../../src/pages/app.tsx" /* webpackChunkName: "component---src-pages-app-tsx" */),
  "component---src-pages-blog-index-tsx": () => import("./../../../src/pages/blog/index.tsx" /* webpackChunkName: "component---src-pages-blog-index-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-dashboard-page-tsx": () => import("./../../../src/pages/dashboard.page.tsx" /* webpackChunkName: "component---src-pages-dashboard-page-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-not-found-404-tsx": () => import("./../../../src/pages/not-found/404.tsx" /* webpackChunkName: "component---src-pages-not-found-404-tsx" */),
  "component---src-pages-not-found-not-found-icon-tsx": () => import("./../../../src/pages/not-found/not-found-icon.tsx" /* webpackChunkName: "component---src-pages-not-found-not-found-icon-tsx" */),
  "component---src-pages-pricing-tsx": () => import("./../../../src/pages/pricing.tsx" /* webpackChunkName: "component---src-pages-pricing-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-products-tsx": () => import("./../../../src/pages/products.tsx" /* webpackChunkName: "component---src-pages-products-tsx" */),
  "component---src-pages-proposal-android-tsx": () => import("./../../../src/pages/proposal/android.tsx" /* webpackChunkName: "component---src-pages-proposal-android-tsx" */),
  "component---src-pages-proposal-api-tsx": () => import("./../../../src/pages/proposal/api.tsx" /* webpackChunkName: "component---src-pages-proposal-api-tsx" */),
  "component---src-pages-proposal-ios-tsx": () => import("./../../../src/pages/proposal/ios.tsx" /* webpackChunkName: "component---src-pages-proposal-ios-tsx" */),
  "component---src-pages-proposal-landing-tsx": () => import("./../../../src/pages/proposal/landing.tsx" /* webpackChunkName: "component---src-pages-proposal-landing-tsx" */),
  "component---src-pages-proposal-tsx": () => import("./../../../src/pages/proposal.tsx" /* webpackChunkName: "component---src-pages-proposal-tsx" */),
  "component---src-pages-qrcode-index-tsx": () => import("./../../../src/pages/qrcode/index.tsx" /* webpackChunkName: "component---src-pages-qrcode-index-tsx" */),
  "component---src-pages-qrcode-menu-index-tsx": () => import("./../../../src/pages/qrcode/menu/index.tsx" /* webpackChunkName: "component---src-pages-qrcode-menu-index-tsx" */),
  "component---src-pages-terms-of-service-tsx": () => import("./../../../src/pages/terms-of-service.tsx" /* webpackChunkName: "component---src-pages-terms-of-service-tsx" */),
  "component---src-pages-terms-tsx": () => import("./../../../src/pages/terms.tsx" /* webpackChunkName: "component---src-pages-terms-tsx" */),
  "component---src-pages-thank-you-tsx": () => import("./../../../src/pages/thank-you.tsx" /* webpackChunkName: "component---src-pages-thank-you-tsx" */)
}

